import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const SignIn = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState('');

  /**
   * Checks the visibility of password field
   */
  const displayPassword = () => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  /**
   * On submit will check for valid user login credentials.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    /**
     * Axios header configuration.
     */
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    /**
     * Checks if sign field are empty or if user is banned.
     * If both are false user logs in.
     */
    try {
      if (password === '' || email === '') {
        setError('Please enter your email and password');
      } else {
        let getUser = await axios.get(`/User/Email/${email}`, config);
        if (getUser.data.userType === 'Banned') {
          setError(
            //Add a link to a "ban appeal" page, would funciton similarly to the contact us page but the subject line is locked as "Ban Appeal"
            'You have been banned from accessing the website for violating our terms of service. \nIf you feel like you have been wrongfully banned, please submit an appeal for review.'
          );
        } else if (getUser.data.confirmed === false) {
          setError('Please confirm your account. Check Spam ');
        } else {
          let user = await axios.post('/SignIn', { email, password }, config);

          //Set the userData sessionStorage variable to the data of the user when they successfully sign in
          //Note - to get data from this sessionStorage variable, you have to use
          //JSON.parse(sessionStorage.getItem('userData')).DATA, where DATA is the data that you want to grab
          sessionStorage.removeItem('userData');
          sessionStorage.setItem('userData', JSON.stringify(getUser.data));

          sessionStorage.removeItem('authToken');
          sessionStorage.setItem('authToken', user.data);

          let id = sessionStorage.getItem('authToken');
          let userType = await axios.get(`/User/${id}`);

          sessionStorage.removeItem('userType');
          sessionStorage.setItem('userType', userType.data.userType);

          sessionStorage.removeItem('isSignIn');
          sessionStorage.setItem('isSignIn', true);

          history.push('/');
          window.location.reload();
        }
      }
    } catch (error) {
      setError('Please enter a valid Email and Password');
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className='inputArea'>
        <Col>
          <Row className='my-4'>
            <h1> Sign In </h1>
          </Row>
          <Row className='my-3'>
            <input
              useref='email'
              type='text'
              placeholder='Email'
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Row>
          <Row className='my-3'>
            <input
              useref='password'
              type={isVisible ? 'text' : 'password'}
              placeholder='Enter Password'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Row>
          <Row className='my-3'>
            <Col>
              <input type='checkbox' onChange={displayPassword} />
              <label
                htmlFor='checkbox-input'
                className='round-slider-container'
              >
                Show Password
              </label>
            </Col>
            <Col>
              <h6>
                <small>
                  <Link to='/Forgotpassword'>Forgot Password?</Link>
                </small>
              </h6>
            </Col>
            <Button className='btn-block' type='submit'>
              SIGN IN
            </Button>
          </Row>
          <Row>
            <span className='errorMessage'>{error}</span>
          </Row>
          <Row>
            <Row className='my+5'>
              <h6>
                <small>
                  <Link to='/Register'>
                    Don't have an account? Click here to create an account
                  </Link>
                </small>
              </h6>
            </Row>
          </Row>
        </Col>
      </Form>
    </>
  );
};

export default SignIn;
