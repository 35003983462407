import React from "react";
import { useState, useEffect } from 'react'
import { Form, Button } from "react-bootstrap";
import axios from "axios";

const AdminHomepageGallery = () => {
    const [previewSource, setPreviewSource] = useState('');
    const [gallery, setGallery] = useState([]);

    useEffect(async () => {
        await axios.get('/gallery').then((response) => {
            setGallery(response.data);
        });
    }, []);

    const config = {
        headers: {
          'content-type': 'application/json',
        },
    };

    const handleChange = async (e) => {
        const file = e.target.files[0];
        previewFile(file);
    };
    
    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!previewSource) return;    
        updateImage(previewSource);
    };

    const updateImage = async (base64EncodedImage) => {
        try {
            const imageData = new FormData();

            imageData.append('file', base64EncodedImage);
            imageData.append('upload_preset', 'home_image');

            let imageUpload = await axios.post(
                'https://api.cloudinary.com/v1_1/canada-horse-auction-alternative/image/upload',
                imageData
            );
            
            let response = await axios.patch(
                '/gallery/update',
                {imageUrl: imageUpload.data.url},
                config
            );

            window.location.assign('/AdminHomepageGallery');
        } catch (error) {
        }
    };

    return (
        <div>
            <Form onSubmit={handleSubmit} encType='multipart/form-data'>  
                <input 
                    name='image'
                    type='file'
                    onChange={handleChange}
                />  
                <Button type="submit">Add Image</Button>
            </Form>
            {previewSource && (
                <div id="previewImage">
                    <p>Preview Image:</p>
                    <img src={previewSource} alt='chosen image' height='350'/>
                </div>
            )}

            <div>
                {gallery.map((val) => {
                return (
                    <img
                    className='galleryImage'
                    src={val.imageUrl} 
                    />
                );
                })}
            </div>
        </div>     
    );
};

export default AdminHomepageGallery;