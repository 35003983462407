import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import '../Visuals/_UserInfoList.scss';

/**
 *
 * @param {the data passed from the users in the database} data
 * @returns The list of the users in the database.
 */
const AdminUserInfoList = ({ data }) => {
  const { firstName, lastName, email, _id, userType } = data;

  /**
   * This is called when the delete button is press to delete the user.
   */
  const deleteUser = async () => {
    if (window.confirm('Did you want to delete this user?')) {
      try {
        await axios.delete(`/user/delete/${_id}`);
        window.location.assign('/AdminUserListPage');
      } catch (error) {
      }
    }
  };
  /**
   * Gets the ban user route.
   */
  const banUser = async () => {
    if (window.confirm('Did you want to ban this user')) {
      try {
        await axios.patch(`/user/ban/${_id}`);
        window.location.assign('/AdminUserListPage');
      } catch (error) {
      }
    }
  };
  /**
   * Gets the unban user route.
   */
  const unbanUser = async () => {
    if (window.confirm('Did you want to unban this user')) {
      try {
        await axios.patch(`/user/unBan/${_id}`);
        window.location.assign('/AdminUserListPage');
      } catch (error) {
      }
    }
  };
  return (
    <div className='userinfoList'>
      <Row>
        <Card>
          <Card.Title>
            <Button onClick={deleteUser} className='DeleteButton' type='submit'>
              Delete
            </Button>
            {userType === 'User' || userType === 'Admin' ? (
              <Button onClick={banUser} className='DeleteButton' type='submit'>
                Ban User
              </Button>
            ) : (
              ''
            )}

            {userType === 'Banned' ? (
              <Button
                onClick={unbanUser}
                className='DeleteButton'
                type='submit'
              >
                unBan User
              </Button>
            ) : (
              ''
            )}
          </Card.Title>
          <Col>
            <Card.Body>
              <Card.Text>{'FirstName: ' + firstName}</Card.Text>
              <Card.Text>{'LastName: ' + lastName}</Card.Text>
              <Card.Text>{'Type of user: ' + userType}</Card.Text>
              <Card.Text>{'Email: ' + email}</Card.Text>
            </Card.Body>
          </Col>
        </Card>
      </Row>
    </div>
  );
};

AdminUserInfoList.propTypes = {
  userLists: PropTypes.object,
};

export default AdminUserInfoList;
