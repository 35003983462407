import React from 'react';
import AdminAddAuctionForm from '../components/AdminAddAuctionsForm.js';

/**
 * Grabs the AdminAddAuctionsForm.js to show on the screen. This is the form where the admin wants to add an upcoming auctions.
 * @returns The AdminaddAuctionForm commponent.
 */
const AdminAuctionPage = () => {
  return (
    <div>
      <AdminAddAuctionForm />
    </div>
  );
};

export default AdminAuctionPage;
