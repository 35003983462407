import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const axios = require('axios');

const ForgotPassword = ({ history }) => {
  /**
   * Email and error state.
   */
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  /**
   * Axios header configuration.
   */
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  /**
   * On submit sends an email to change password.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      /**
       * If no errors sends an email to reset password to the provided email then redirects to sign in page after 5000 seconds.
       */
      await axios.post('/Forgotpassword', { email }, config);

      setError('Email Has Been Sent. Check Your Email To Reset');

      setTimeout(() => {
        history.push('/SignIn');
      }, 5000);
    } catch (error) {
      setError('Email is Invalid, Try Again ');
      setEmail('');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className='inputArea'>
        <Col className='my-5'>
          <Row className='my-4'>
            <h1> Forgot Password </h1>
          </Row>
          <Row className='my-3'>
            <input
              name='email'
              type='email'
              placeholder='Email'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              autoFocus
            />
          </Row>
          <Row className='my-3'>
            <Button className='btn-block' type='submit'>
              Reset Password
            </Button>
          </Row>
          <Row>{error && <span className='errorMessage'>{error} </span>}</Row>
          <Row>
            <Row className='my+5'>
              <h6>
                <small>
                  <Link to='/Register'>
                    Don't have an account? Click here to create an account
                  </Link>
                </small>
              </h6>
            </Row>
          </Row>
        </Col>
      </Form>
    </>
  );
};

export default ForgotPassword;
