import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import '../Visuals/_DonationsPage.scss';

/**
 * The rescue listing page code.
 * @param {the data passed from the admin rescue form}
 * @returns Rescue Group page.
 */
const RescuesListings = ({ data }) => {
  const { organization, description, province, webLink, donLink, image, _id } =
    data;

  /**
   * Calls the delete route for a single rescue group.
   */
  const deleteRescueGroup = async () => {
    if (window.confirm('Did you want to delete this Rescue group?')) {
      try {
        await axios.delete(`/rescues/delete/${_id}`);
        window.location.assign('/DonationPage');
      } catch (error) {
      }
    }
  };

  /**
   * Session variable being saved when a user logins into the website and keeps their status as signed in.
   */
  let isSignIn = sessionStorage.getItem('isSignIn');
  /**
   * Session variable being saved of their user type.
   */
  let userType = sessionStorage.getItem('userType');

  /**
   * The form to display the Rescue groups that was put into the database via the AdminAddRescueForm.js.
   */
  return (
    <div className='rescueGroup'>
      <Form>
        <Card>
          <Card.Title>
            {organization}
            {isSignIn === 'true' && userType === 'Admin' ? (
              <Button
                onClick={deleteRescueGroup}
                className='DeleteButton'
                type='button'
              >
                Delete
              </Button>
            ) : (
              ''
            )}
          </Card.Title>
          <Card.Body>
            <Col>
              <Card.Text>Located in {province}</Card.Text>
              <Card.Text> {description} </Card.Text>
            </Col>
            <Col id='donationLinks'>
              <img src={image} />
              <Card.Text>
                <a target='_blank' href={webLink} rel='noreferrer'>
                  Visit their site
                </a>
                <a target='_blank' href={donLink} rel='noreferrer'>
                  Donate
                </a>
              </Card.Text>
            </Col>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
};

RescuesListings.propTypes = {
  listing: PropTypes.object,
};

export default RescuesListings;
