import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState, convertFromHTML, convertFromRaw  } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from 'html-react-parser';
import draftToHtml from "draftjs-to-html";
import { useHistory } from 'react-router-dom';

const AdminEditPrivacyForm = () => { 
    let history = useHistory();

    const [ErrorMsg, setErrorMsg] = useState('');
    
    const [data, setData] = useState('');
   
    useEffect(async () => {
        await axios.get(`/privacy`).then((response) => {
            setData(response.data.policy);
        });
    }, []);

    const [contentState, setContentState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(``))));

    const confirmEdit = async (e) => {
        e.preventDefault();

        try {
            if (contentState == '')
            {
                setErrorMsg("Please fill in the blanks");
            }
            else{
                await axios.patch(`/privacy/edit`, {
                    policy: draftToHtml(convertToRaw(contentState.getCurrentContent())),
                });   
            }
            history.push(`/PrivacyPolicy`);
            window.location.reload();
        }
        catch (error){
            setErrorMsg(error);
        }
    };

    const onContentStateChange = (contentState) => {
        setContentState(contentState);
      };

    const cancelEdit = (e) => {
        e.preventDefault();
        history.push(`/PrivacyPolicy`);
        window.location.reload();
    };

return(
    <div>
        <Row>
            <h1>Edit Privacy Policy</h1>
        </Row>
            <Card>
                <Col className='wysiwygImg'>
                <Editor
                    name='policy'
                    editorState={contentState}
                    onEditorStateChange={onContentStateChange}    
                    placeholder='Edit Privacy Policy'
                    toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        colorPicker: {colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgba(255, 255, 255, .1)'], },
                    }}
                />
                <div>
                    <h3><p><strong>Current Page:</strong></p></h3>
                    {parse(`${data}`)}
                </div>
                     <Row id=''>
                        <button onClick={(e) => cancelEdit(e)}>Cancel</button>
                        <button onClick={(e) => confirmEdit(e)}>Confirm</button>
                    </Row>
                    <p>{ErrorMsg}</p>
                </Col>
            </Card>
    </div>
    );
    
};
export default AdminEditPrivacyForm;