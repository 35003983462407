import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import axios from 'axios';

const Profile = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: ''
  };

  const [getProfile, setProfile] = useState(initialState);
  const [initialProfile, setInitialProfile] = useState(initialState);
  const [selectedImage, setSelectedImage] = useState('');
  const [formData, setFormData] = useState(initialState);
  const [getError, setError] = useState('');
  const [checked, setChecked] = useState(false);
  let id = sessionStorage.getItem('authToken');

  const url =
  'https://api.cloudinary.com/v1_1/canada-horse-auction-alternative/image/upload';

  const cloudConfig = {
   headers: {
     'Content-Type': 'multipart/form-data',
   },
 };

  const { imageUrl, imageUrlTwo } = formData;

  /**
   * Gets a user profile and sets it to state.
   */
  useEffect(async () => {
    let profile = await axios.get(`User/${id}`);
    setProfile(profile.data);
    setInitialProfile(profile.data);
  }, []);

  /**
   * Header configuration for axios.
   */
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  /**
   * On submit updates the user profile.
   * @param {*} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let emailExists = await axios.get(`/User/Email/${getProfile.email}`);

      /**
       * Checks if new entered email already exists in the database and checks if the email field was updated.
       * Then updates the user profile.
       */
      if (
        initialProfile.email.toString() === getProfile.email.toString() ||
        emailExists.data.toString() !== getProfile.email.toString()
      ) {
          const imageData = new FormData();
          
          /**
           * If the user updates the fields but does not upload a new image, 
           * the current image will stay and the new info will be updated.
           */
          if(selectedImage === '' && getProfile.firstName !== '') {
            if (getProfile.imageUrl !== ''){
              await axios.patch(
                `/User/${id}`,
                {
                  firstName: getProfile.firstName,
                  lastName: getProfile.lastName,
                  email: getProfile.email,
                  phoneNumber: getProfile.phoneNumber,
                  imageUrl: getProfile.imageUrl,
                },
                config
              );
            }
            else{
              await axios.patch(
                `/User/${id}`,
                {
                  firstName: getProfile.firstName,
                  lastName: getProfile.lastName,
                  email: getProfile.email,
                  phoneNumber: getProfile.phoneNumber,
                  imageUrl: "",
                },
                config
              );
            }
          }
          
          //If the user has no image, it will upload the user image.
           else if (getProfile.imageUrl === '') {
            imageData.append('file', selectedImage);
            imageData.append('upload_preset', 'image_upload');
            let imageUpload = await axios.post(url, imageData, cloudConfig);

            await axios.patch(
              `/User/${id}`,
              {
                firstName: getProfile.firstName,
                lastName: getProfile.lastName,
                email: getProfile.email,
                phoneNumber: getProfile.phoneNumber,
                imageUrl: imageUpload.data.url,
              },
              config
            );
          }

          //If they user wants to upload a new image, it will replace the old one with the new one.
          else if (selectedImage !== '') {
            imageData.append('file', selectedImage);
            imageData.append('upload_preset', 'image_upload');
            let imageUpload = await axios.post(url, imageData, cloudConfig);
          

            await axios.patch(
              `/User/${id}`,
              {
                firstName: getProfile.firstName,
                lastName: getProfile.lastName,
                email: getProfile.email,
                phoneNumber: getProfile.phoneNumber,
                imageUrl: imageUpload.data.url,
              },
              config
            );
          }
        
          //If the checkbox is true, it will remove their profile picture.
        if (checked)
        {
          await axios.patch(
            `/User/${id}`,
            {
              firstName: getProfile.firstName,
              lastName: getProfile.lastName,
              email: getProfile.email,
              phoneNumber: getProfile.phoneNumber,
              imageUrl: "",
            },
            config
          );
        }

        setInitialProfile.email = getProfile.email;
        window.location.reload();
      } else {
        setError('This Email Address Is Already Being Used');
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    } catch (error) {
    }
  };

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };

  /**
   * On input fields updates state when it is changed.
   */
  const handleChange = (e) => {
    setProfile({ ...getProfile, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className='inputArea' id='profilePage'>
        <Col className='my-5'>
          <Row className='my-4'>
            <h1> Profile Page </h1>
          </Row>
          <Row className='my-3'>
            <label>First Name: </label>
            <input
              name='firstName'
              placeholder={getProfile.firstName}
              type='text'
              onChange={handleChange}
              value={getProfile.firstName}
              autoFocus
            ></input>
          </Row>
          <Row className='my-3'>
            <label>Last Name: </label>
            <input
              name='lastName'
              placeholder={getProfile.lastName}
              type='text'
              onChange={handleChange}
              value={getProfile.lastName}
            />
          </Row>
          <Row className='my-3'>
            <label>Phone Number: </label>
            <input
              type='tel'
              name='phoneNumber'
              placeholder={getProfile.phoneNumber}
              pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
              maxLength='12'
              title='format(eg: 0001112222) no spaces'
              value={getProfile.phoneNumber}
              onChange={handleChange}
            />
          </Row>
          <Row className='my-3'>
            <label>Email:</label>
            <input
              name='email'
              type='email'
              placeholder={getProfile.email}
              onChange={handleChange}
              value={getProfile.email}
            />
            <span className='errorMessage'>{getError}</span>
            <Row className='my-3'>
                <input
                name='file'
                type='file'
                onChange={(e) => setSelectedImage(e.target.files[0])}
                ></input>
            </Row> 
            { getProfile.imageUrl !== '' ?(
            <div className='deleteBtn'>
              <FormControlLabel control={<Checkbox checked={checked} onChange={handleChangeCheck} />} label="Check to Delete Profile Picture" />
            </div> ) : (
              ''
            )
            }
            <img src={getProfile.imageUrl} />
            <Button className='btn-block' type='submit'>
              Change Profile
            </Button>
          </Row>
          <Row className='my-3'></Row>
        </Col>
      </Form>
      
    </>
  );
};

export default Profile;
