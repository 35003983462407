import { Col, Card } from 'react-bootstrap';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../Visuals/_ShowListing.scss';
import { useHistory } from 'react-router-dom';

/**
 * the listing page code.
 * @param {the listing data passed from the indiviudal listing on the listing page} data
 * @param {the info of the user that posted the listing (for email and name stuff)} lister
 * @returns listing page.
 */
const ListItems = ({ data, lister }) => {
  const { title, description, selling, imageUrl, price, province, city, _id, sex, age } =
    data;

  const { firstName, lastName, email } = lister;

  let history = useHistory();

  /**
   * Calling the delete route to delete the single post
   */
  const deletePost = async () => {
    if (window.confirm('Did you want to remove this post')) {
      try {
        await axios.delete(`/post/delete/${_id}`);
      } catch (error) {
      }
      window.location.assign('/Listings');
    }
  };
  let isSignIn = sessionStorage.getItem('isSignIn');
  let userType = sessionStorage.getItem('userType');

  /**
   * Editing a post
   * @param {data to be sent to the editing page} data
   */
  const editPost = (data, e) => {
    e.preventDefault();
    history.push('/ListItemEdit', data);
    window.location.reload();
  };

  return (
    <>
      <Card>
        <Card.Title>
          Title: {title}
          {
            //Checks if the user is the poster or an admin.
            (isSignIn === 'true' && userType === 'Admin') ||
            (isSignIn &&
              JSON.parse(sessionStorage.getItem('userData')).id ===
                data.user) ? (
              <>
                <button
                  onClick={deletePost}
                  className='DeleteButton'
                  type='button'
                >
                  Remove Post
                </button>
                <button
                  onClick={(e) => editPost(data, e)}
                  className='EditButton'
                  type='button'
                >
                  Edit Post
                </button>
              </>
            ) : (
              ''
            )
          }
        </Card.Title>
        <Col>
          <Card.Body className='flex'>
            <Col>
              <div>
                <img
                  variant='left'
                  alt='HorseImages'
                  src={imageUrl}
                  className='bigImage'
                />
              </div>
              <div className='flex'>
                <div className='smallImage'></div>
                <div className='smallImage'></div>
                <div className='smallImage'></div>
                <div className='smallImage'></div>
              </div>
            </Col>
            <Col>
              <Card.Text>
                <div className='listingInfo'>
                  {
                    //checks to see if the user is signed in.
                    isSignIn ? (
                      <>
                        <p>
                          {firstName}, {lastName}
                        </p>
                        <p>{email}</p>
                      </>
                    ) : (
                      <p>Sign in to see the contact information of the user</p>
                    )
                  }
                  <p>
                    Looking to {selling ? 'sell' : 'buy'/* Eventually remove this section once there is more differentiation
                    between buying and selling ads */} a horse for rehoming
                  </p>
                  <p> {'Sex: ' + sex} </p>
                  <p> {'Age: ' + age} </p>
                  <p> {'Cost: ' + price} </p>
                  <p> {'Province: ' + province}</p>
                  <p> {'City: ' + city}</p>
                  <p>{description}</p>
                </div>
              </Card.Text>
            </Col>
          </Card.Body>
        </Col>
      </Card>
    </>
  );
};

ListItems.propTypes = {
  listing: PropTypes.object,
};

export default ListItems;
