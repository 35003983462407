import React from 'react';
import Karma from '../images/Karma.jpg';
import axios from 'axios';
import { useEffect, useState } from 'react';

/**
 * The about us page.
 */
const AboutUs = () => {
  const [AboutUs, setAboutUs] = useState([]);
  
  useEffect(async () => {
    await axios.get(`/aboutus`).then((response) => {
      setAboutUs(response.data[0]);
    });
  }, []);

  return (
    <div className='AboutUs'>
      <h1>ABOUT US</h1>
      <div>
        <h4>{ AboutUs.title }</h4>
        
        <p className='whiteSpace'>{ AboutUs.description }</p>
        
        <img src={Karma} alt='Karma The Horse'></img>
        <p>
          Meet Karma, she is one of the horses that was posted on my CHAA
          Facebook Group. Myself & 3 other amazing women helped to rescue her
          from a Manitoba Auction after our proxy had a bidding war with the
          kill buyer. Karma was dropped off to the auction emaciated, still
          producing milk because her baby had just been taken from her. Had we
          not seen and purchased her, this approximately 22 year old horse would
          have been crammed on a transport truck full of other horses (50+) that
          didn’t get purchased at that auction. She now has a full time Mom and
          a beautiful retirement at the farm where I board my two horses, both
          of which were also auction bound had I not adopted them.
        </p>
        
        <p>
          Student Team:
          <br/>
          Phase 1 -
          <br/>
          Mandip Kaur (Project Manager)
          <br/>
          Navjot Singh (Business Analyst)
          <br/>
          Matthew Hole (Developer)
          <br/>
          Matthew Wilson (Developer)
          <br/>
          Michael Moroz (Developer)
          <br/>
          Mowen Lucero (Developer)
        </p>
        <p>
          Phase 2 -
          <br/>
          Jashanjot Pandher (Project Manager)
          <br/>
          Kristofer Campbell (Information Security)
          <br/>
          Tony Le (Developer)
          <br/>
          Isaac Macalinao (Developer)
          <br/>
          Sahil Patel (Developer)
        </p>

        <p>
          There is no cost to post an advertisement for your horse(s). Products
          & Services will be required to pay a fee to advertise on CHAA in order
          to offset the cost of operation. Please visit the Advertising
          Opportunity page for more information.
        </p>

        <h5>Mission Statement:</h5>
        <p>
          "{AboutUs.statement}"
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
