import React from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";

/**
 * Carousel gallery to display images to the home screen.
 */
const HomepageGallery = () => {

  const [gallery, setGallery] = useState([]);

  useEffect(async () => {
      await axios.get('/gallery').then((response) => {
          setGallery(response.data);
      });
  }, []);

  return (
    <>
        {gallery.map((val, i) => {
          return (
              <img key={i}
              className='d-block w-100'
              src={val.imageUrl} 
              height='600'
              />
          );
          
        })}
        <div className='intro'>
          <h2>
            <strong>Canada’s #1 Horse Classifed Ad Website</strong><br></br>
            <i>“We promote adoption rather than auction where horses are at risk of slaughter”.</i>
          </h2>
        </div>
    </>
  );
};

export default HomepageGallery;
