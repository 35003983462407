import { Row, Col, Card } from 'react-bootstrap';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../Visuals/_ShowListing.scss';
import { useHistory } from 'react-router-dom';

/**
 * Editing a listed item
 * @param {data passed from listing} data
 * @returns editing page
 */
const ListItemEdit = (data) => {
  const { title, description, selling, imageUrl, price, province, city, _id, age, sex } =
    data.location.state;

  let history = useHistory();

  //useStates
  //Note - Should be merged all into one useState eventually
  const [Title, setTitle] = useState(title);
  const [Description, setDescription] = useState(description);
  const [Price, setPrice] = useState(price);
  const [Province, setProvince] = useState(province);
  const [City, setCity] = useState(city);
  const [Age, setAge] = useState(age);
  const [Sex, setSex] = useState(sex);
  const [ErrorMsg, setErrorMsg] = useState('');

  let isSignIn = sessionStorage.getItem('isSignIn');
  let userType = sessionStorage.getItem('userType');

  /**
   * Confirming the edit
   * @param {an event} e
   */
  const confirmEdit = async (e) => {
    e.preventDefault();

    try {
      //Error validation, selling and province aren't listed due to lack of alternatives
      if (Title === '') {
        throw new Error('Please add a title');
      } else if (Description === '') {
        throw new Error('Please add a short description');
      } else if (City === '') {
        throw new Error('Please add your city');
      } else if (Price === '') {
        throw new Error('Please include a price');
      } else if (Price < 0) {
        throw new Error("The price can't be negative");
      } else if (Sex === '') {
        throw new Error("Please include the horse's sex")
      } else if (Age === '') {
        throw new Error('Please include an age');
      } else if (Age < 0) {
        throw new Error("The age can't be negative")
      } else {
        await axios.patch(`/posts/${_id}`, {
          title: Title,
          description: Description,
          province: Province,
          city: City,
          price: Price,
          sex: Sex,
          age: Age,
        });

        history.push(`/Listing/${_id}`);
        window.location.reload();
      }
    } catch (error) {
      //displays the error in text
      setErrorMsg(error);
    }
  };

  /**
   * Cancelling the edit
   * @param {an event} e
   */
  const cancelEdit = (e) => {
    e.preventDefault();
    history.push(`/Listing/${_id}`);
    window.location.reload();
  };

  /**
   * deleting the post
   */
  const deletePost = async () => {
    if (window.confirm('Did you want to remove this post')) {
      try {
        await axios.delete(`/post/delete/${_id}`);
      } catch (error) {
      }
      window.location.assign('/Listings');
    }
  };

  /**
   * all of the fields in the patch are below, seperated by <p> tags.
   * default value is from the useStates
   */
  return (
    <>
      <Card>
        <Card.Title>
          Title:
          <input value={Title} onChange={(e) => setTitle(e.target.value)} />
          {
            //checking if the user is an admin or the poster
            (isSignIn === 'true' && userType === 'Admin') ||
            (isSignIn &&
              JSON.parse(sessionStorage.getItem('userData')).id ===
                data.user) ? (
              <>
                <button
                  onClick={deletePost}
                  className='DeleteButton'
                  type='button'
                >
                  Remove Post
                </button>
              </>
            ) : (
              ''
            )
          }
        </Card.Title>
        <Col>
          <Card.Body className='flex'>
            <Col>
              <div>
                <img
                  variant='left'
                  alt='HorseImages'
                  src={imageUrl}
                  className='bigImage'
                />
              </div>
              <div className='flex'>
                <div className='smallImage'></div>
                <div className='smallImage'></div>
                <div className='smallImage'></div>
                <div className='smallImage'></div>
              </div>
            </Col>
            <Col>
              <Card.Text>
                <p>
                  {'Sex: '}
                  <select
                    name='sex'
                    value={Sex}
                    onChange={(e) => setSex(e.target.value)}
                  >
                    <option name='sex' value='Male'>
                      Male
                    </option>
                    <option name='sex' value='Female'>
                      Female
                    </option>
                  </select>
                </p>
                <p>
                {'Age: '}
                  <input
                    value={Age}
                    placeholder='age'
                    onChange={(e) => setAge(e.target.value)}
                  ></input>
                </p>
                <p>
                  {'Cost: '}
                  <input
                    value={Price}
                    placeholder='price'
                    onChange={(e) => setPrice(e.target.value)}
                  ></input>
                </p>
                <p>
                  {'Province: '}
                  <select
                    name='province'
                    placeholder='Choose a province'
                    value={Province}
                    onChange={(e) => setProvince(e.target.value)}
                  >
                    <option name='province' value='MB'>
                      Manitoba
                    </option>
                    <option name='province' value='BC'>
                      British Columbia
                    </option>
                    <option name='province' value='AB'>
                      Alberta
                    </option>
                    <option name='province' value='SK'>
                      Saskatchewan
                    </option>
                    <option name='province' value='ON'>
                      Ontario
                    </option>
                    <option name='province' value='QC'>
                      Quebec
                    </option>
                    <option name='province' value='NB'>
                      New Brunswick
                    </option>
                    <option name='province' value='NS'>
                      Nova Scotia
                    </option>
                    <option name='province' value='PE'>
                      PEI
                    </option>
                    <option name='province' value='NL'>
                      Newfoundland and Labrador
                    </option>
                    <option name='province' value='YT'>
                      Yukon
                    </option>
                    <option name='province' value='NT'>
                      Northwest Territories
                    </option>
                    <option name='province' value='NU'>
                      Nunavut
                    </option>
                  </select>
                </p>
                <p>
                  {'City: '}
                  <input
                    value={City}
                    placeholder='city'
                    onChange={(e) => setCity(e.target.value)}
                  />
                </p>
                <p>
                  <textarea
                    value={Description}
                    placeholder='description'
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </p>
                <label className='errorMessage'>{ErrorMsg.toString()}</label>
              </Card.Text>
              <button onClick={(e) => cancelEdit(e)}>Cancel</button>
              <button onClick={(e) => confirmEdit(e)}>Confirm</button>
            </Col>
          </Card.Body>
        </Col>
      </Card>
    </>
  );
};

ListItemEdit.propTypes = {
  listing: PropTypes.object,
};

export default ListItemEdit;
