import React from 'react';
import AdminEditPrivacyForm from '../components/AdminEditPrivacyForm.js';


const AdminEditTOS = () => {
  return (
    <div>
      <AdminEditPrivacyForm />
    </div>
  );
};

export default AdminEditTOS;