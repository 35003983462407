import React from "react";
import { useState, useEffect } from 'react'
import axios from "axios";

const AdminEditAds = () => {
    const [ads, setAds] = useState([]);

    useEffect(async () => {
        await axios.get('/Ads').then((response) => {
            setAds(response.data);
        });
    }, []);

    const config = {
        headers: {
          'content-type': 'application/json',
        },
    };

    const deleteImage = (id) => {
        axios.delete(`/Ads/delete/${id}`);
        window.location.assign('/AdminEditAds');
    }

    return (
        <div>
            <h2>Edit Ads</h2>
            <div>
                {ads.map((val) => {
                return (
                <div>
                    <img
                    className='editAds'
                    src={val.imageUrl} 
                    />

                    <button onClick={() => {deleteImage(val._id);}}>
                        Delete
                    </button>
                </div> 
                );
                })}
            </div>
        </div>     
    );
};

export default AdminEditAds;