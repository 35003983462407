import React, { useState } from 'react';
import { Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';
const AdPost = () => {
  /**
   * initial state of the form.
   */
  const initialState = {
    websiteUrl: '',
    adType: 'ad',
  };

  const [formData, setFormData] = useState(initialState);
  const [selectedImage, setSelectedImage] = useState('');

  /**
   * Updates form data state when input field is changed.
   */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * Axios image header configuration.
   */
  const cloudConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  /**
   * On submit uploads ads to database.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const imageData = new FormData();
      imageData.append('file', selectedImage);
      imageData.append('upload_preset', 'ad_image');

      /**
       * Uploads image to Cloudinary
       */
      let imageUpload = await axios.post(
        `https://api.cloudinary.com/v1_1/canada-horse-auction-alternative/image/upload`,
        imageData,
        cloudConfig
      );

      /**
       * Uploads ads data to mongoDB with type of ads and company url as well as .
       */
      await axios.post('/Ads', {
        data: formData,
        imageUrl: imageUpload.data.url,
      });
      window.location.reload();
    } catch (error) {
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className='inputArea'>
        <Row>
          <h1>AD POST FORM</h1>
        </Row>
        <Row className='my-3'>
          <input
            name='file'
            type='file'
            onChange={(e) => setSelectedImage(e.target.files[0])}
            autoFocus
          />
        </Row>
        <Row className='my-3'>
          <input
            name='websiteUrl'
            type='text'
            onChange={handleChange}
            placeholder='Website Link (optional)'
          />
        </Row>
        <Row className='my-3'>
          <select name='adType' value={formData.adType} onChange={handleChange}>
            <option name='ad' value={'ad'}>
              Ad
            </option>
          </select>
        </Row>

        <Button className='btn-block' type='submit'>
          Post Ad
        </Button>
      </Form>
    </>
  );
};

export default AdPost;
