import React from 'react';
import AdminEditTOSForm from '../components/AdminEditTOSForm.js';
import axios from "axios";


const AdminEditTOS = () => {
  return (
    <div>
      <AdminEditTOSForm />
    </div>
  );
};

export default AdminEditTOS;