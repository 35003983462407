import React from 'react';
import { useState } from 'react';
import { Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';

/**
 * Sets the rescue data from the form.
 * @returns admin rescue form.
 */
const AdminAddRescueForm = () => {
  const [rescueData, setRescueData] = useState({
    organization: '',
    description: '',
    province: '',
    webLink: '',
    donLink: '',
  });

  const [selectedImage, setSelectedImage] = useState('');
  const [error, setError] = useState('');

  const config = {
    headers: {
      'content-type': 'application/json',
    },
  };

  const { organization } = rescueData;
  const { description } = rescueData;
  const { province } = rescueData;
  const { webLink } = rescueData;
  const { donLink } = rescueData;

  /**
   * On submit will add a new rescue group to the database with a image with it.
   * @param {*} e
   */
  const submit = async (e) => {
    e.preventDefault();
    setError('');
    if (organization === '') {
      setError('Please enter organization');
    } else if (description === '') {
      setError('Please enter description');
    } else if (province === '') {
      setError('Please choose a province');
    } else if (webLink === '') {
      setError('Please enter a URL link to the rescue group');
    } else if (donLink === '') {
      setError('Please enter donation URL for the rescue group');
    } else {
      try {
        /**
         * This grabs the image in form to upload it to connecting cloudinary to save the image.
         */
        const imageData = new FormData();

        imageData.append('file', selectedImage);

        imageData.append('upload_preset', 'donation_image');

        /**
         * This grabs the image in form to upload it to the connecting cloudinary to save the image.
         */
        let imageUpload = await axios.post(
          'https://api.cloudinary.com/v1_1/canada-horse-auction-alternative/image/upload',

          imageData
        );
        await axios.post(
          '/rescues',
          { data: rescueData, image: imageUpload.data.url },
          config
        );

        window.location.assign('/DonationPage');
      } catch {}
    }
  };

  /**
   * Handles any changes in the data being posted.
   */
  const handleChange = (e) => {
    setRescueData({ ...rescueData, [e.target.name]: e.target.value });
  };
  /**
   *The form in which the Admin user will fill out to post a Rescue of their choice.
   */
  return (
    <div className='inputArea'>
      <Row>
        <h1>Add Rescue Groups</h1>
      </Row>
      <Form onSubmit={submit}>
        <Row className='my-3'>
          <input
            name='organization'
            value={organization}
            type='text'
            placeholder='Name of organization'
            onChange={(e) => handleChange(e)}
          ></input>
        </Row>
        <Row className='my-3'>
          <textarea
            name='description'
            value={description}
            type='text'
            placeholder='description'
            onChange={(e) => handleChange(e)}
          ></textarea>
        </Row>
        <Row className='my-3'>
          <select
            name='province'
            placeholder='Choose a province'
            value={province}
            onChange={(e) => handleChange(e)}
          >
            <option name='province'>Please choose a province</option>
            <option name='province' value='MB'>
              Manitoba
            </option>
            <option name='province' value='BC'>
              British Columbia
            </option>
            <option name='province' value='AB'>
              Alberta
            </option>
            <option name='province' value='SK'>
              Saskatchewan
            </option>
            <option name='province' value='ON'>
              Ontario
            </option>
            <option name='province' value='QC'>
              Quebec
            </option>
            <option name='province' value='NB'>
              New Brunswick
            </option>
            <option name='province' value='NS'>
              Nova Scotia
            </option>
            <option name='province' value='PE'>
              PEI
            </option>
            <option name='province' value='NL'>
              Newfoundland and Labrador
            </option>
            <option name='province' value='YT'>
              Yukon
            </option>
            <option name='province' value='NT'>
              Northwest Territories
            </option>
            <option name='province' value='NU'>
              Nunavut
            </option>
          </select>
        </Row>
        <Row className='my-3'>
          <input
            name='webLink'
            value={webLink}
            type='text'
            placeholder='Place website URL (Link)'
            onChange={(e) => handleChange(e)}
          ></input>
        </Row>
        <Row className='my-3'>
          <input
            name='donLink'
            value={donLink}
            type='text'
            placeholder='Place Donation URL (Link)'
            onChange={(e) => handleChange(e)}
          ></input>
        </Row>
        <Row className='my-3'>
          <input
            name='image'
            type='file'
            onChange={(e) => setSelectedImage(e.target.files[0])}
          ></input>
        </Row>
        <span className='errorMessage'>{error}</span>
        <Row>
          <Button className='btn btn-primary' type='submit'>
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AdminAddRescueForm;
