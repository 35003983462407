import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Row, Col, Button, Form } from 'react-bootstrap';

//Initial state variable for the form data
//user is being set to the id of the user who is currently signed in and posting
//selling is set to true by default
const initialFormData = {
  user: JSON.parse(sessionStorage.getItem('userData')).id,
  title: '',
  description: '',
  selling: 'true',
  province: '',
  city: '',
  imageUrl: '',
  price: '',
  sex: '',
  age: '',
};

//Image uploading url, used in axios post request to post the images to cloudinary
const url =
  'https://api.cloudinary.com/v1_1/canada-horse-auction-alternative/image/upload';

//Replace tags with 4 main fields (breed, age, sex, colour)
//let tempArray = [];

/**
 * Creates the page used for posting listings to the database
 * @returns PostForm
 */
const PostForm = () => {
  //useState hooks
  const [formData, setFormData] = useState(initialFormData);
  const [selectedImage, setSelectedImage] = useState('');
  const [error, setError] = useState('');

  //Deconstructing formData
  const { title, description, selling, province, city, imageUrl, price, sex, age} =
    formData;

  //Config for posting the listing
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  //Config for posting images
  const cloudConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  /**
   * Function that will run whenever a form is submitted
   */
  const submit = async (e) => {
    e.preventDefault();
    //Set error state to nothing
    setError('');

    //Dealing with errors/checking that fields are filled in (Works but needs improvement)
    //Sets error state to the appropriate error if there is one so that it can be displayed,
    //and stop any post requests from being sent

    if (title === '') {
      setError('Please add a title');
    } else if (description === '') {
      setError('Please add a short description');
    } else if (sex === '') {
      setError("Please select the horse's sex");
    } else if (age === '') {
      setError("Please add the horse's age");
    } else if (province === '') {
      setError('Please select your province');
    } else if (city === '') {
      setError('Please add your city');
    } else if (price === '') {
      setError('Please include a price');
    } else if (price < 0) {
      setError("The price can't be negative");
    } else if (selectedImage === '') {
      setError('Please add a picture of your horse');
    } else {
      try {
        //Upload image to cloudinary
        //Create new FormData() object
        const imageData = new FormData();
        //Append the given file and the proper Cloudinary upload preset
        imageData.append('file', selectedImage);
        imageData.append('upload_preset', 'image_upload');
        //Post using the Cloudinary url
        let imageUpload = await axios.post(url, imageData, cloudConfig);

        //Post to the database, using the imageUrl from the cloudinary post response
        let postResponse = await axios.post('/posts', {
          data: formData,
          imageUrl: imageUpload.data.url,
        }, config);

        //Redirect user
        window.location.assign('/');
      } catch {
        // setError(thing.postResponse.data.error);
      }
    }
  };

  /**
   * Handles changes in the form's data
   */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className='inputArea'>
      <Form onSubmit={submit} encType='multipart/form-data'>
        <Col className='my-5'>
          <Row className='my-3'>
            <input
              name='title'
              value={title}
              type='text'
              placeholder='Post Title'
              onChange={(e) => handleChange(e)}
            ></input>
          </Row>

          <Row>
            <textarea
              name='description'
              value={description}
              type='text'
              placeholder='description'
              onChange={(e) => handleChange(e)}
            ></textarea>
          </Row>

          <Row className='my-3'>
            <select
              name='sex'
              placeholder="Please choose the horse's sex"
              value={sex}
              onChange={(e) => handleChange(e)}
            >
                <option name='sex' value=''>
                  Please choose the sex of the horse
                </option>
                <option name='sex' value='Male'>
                  Male
                </option>
                <option name='sex' value='Female'>
                  Female
                </option>
            </select>
          </Row>

          <Row className='my-3'>
            <input
              name='age'
              value={age}
              type='number'
              placeholder='Horse Age'
              onChange={(e) => handleChange(e)}
            ></input>
          </Row>

          <Row className='my-3'>
            <select
              name='selling'
              value={selling}
              onChange={(e) => handleChange(e)}
            >
              <option name='selling' value={true}>
                Selling
              </option>
              <option name='selling' value={false}> 
                Buying
              </option>
            </select>
          </Row>

          <Row className='my-3'>
            <select
              name='province'
              placeholder='Choose a province'
              value={province}
              onChange={(e) => handleChange(e)}
            >
              <option name='province' value=''>
                Please choose a province
              </option>
              <option name='province' value='MB'>
                Manitoba
              </option>
              <option name='province' value='BC'>
                British Columbia
              </option>
              <option name='province' value='AB'>
                Alberta
              </option>
              <option name='province' value='SK'>
                Saskatchewan
              </option>
              <option name='province' value='ON'>
                Ontario
              </option>
              <option name='province' value='QC'>
                Quebec
              </option>
              <option name='province' value='NB'>
                New Brunswick
              </option>
              <option name='province' value='NS'>
                Nova Scotia
              </option>
              <option name='province' value='PE'>
                PEI
              </option>
              <option name='province' value='NL'>
                Newfoundland and Labrador
              </option>
              <option name='province' value='YT'>
                Yukon
              </option>
              <option name='province' value='NT'>
                Northwest Territories
              </option>
              <option name='province' value='NU'>
                Nunavut
              </option>
            </select>
          </Row>

          <Row className='my-3'>
            <input
              name='city'
              value={city}
              type='text'
              placeholder='City'
              onChange={(e) => handleChange(e)}
            ></input>
          </Row>

          <Row className='my-3'>
            <input
              name='price'
              value={price}
              type='number'
              placeholder='$0.00'
              onChange={(e) => handleChange(e)}
            ></input>
          </Row>

          <Row className='my-3'>
            <input
              name='file'
              type='file'
              onChange={(e) => setSelectedImage(e.target.files[0])}
            ></input>
          </Row>

          <span className='errorMessage'>{error}</span>
          <Row className='my-4'>
            <Button className='btn-block' type='submit'>
              Post Ad
            </Button>
          </Row>
        </Col>
      </Form>
    </div>
  );
};

export default PostForm;

//Code/code changes to allow multiple image to be uploaded (Mostly working)

//Note 1 - Will need to change the onChange() event method in the field where images
//are submitted in the form to the new handleImages function (below)

//Note 2 - Some changes are also required in the route, which are listed there

//Put in trycatch
/* const imageData = new FormData();

for (const files in tempArray) {
imageData.append('file', tempArray[files]);
imageData.append('upload_preset', 'image_upload');
console.log(imageData);
let imageUpload = await axios.post(url, imageData, cloudConfig);
console.log(imageUpload);
console.log(imageUpload.data);
setFormData({imageUrl: imageUrl.push(imageUpload.data.url)})
console.log(formData)
}

let postResponse = await axios.post('/posts', formData);
console.log(postResponse);
console.log(postResponse.data);
window.location.assign('/'); */

//Put as own function
/* const handleImages = (e) => {
    e.preventDefault();
    tempArray.push(e.target.files[0]);
  } */
