import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import parse from 'html-react-parser';

/**
 * The privacy policy page.
 */
const PrivacyPolicy = () => {
  const [privatePolicy, setPolicy] = useState([]);

  useEffect(async () => {
    await Axios.get('/privacy').then((response) => {
      setPolicy(response.data.policy);      
    });
  }, []);

  return (
    <>
      <h1>PRIVACY POLICY</h1>
      <p> {parse(`${privatePolicy}`)}</p>
    </>
  );
};

export default PrivacyPolicy;
