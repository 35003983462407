import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import Register from './screens/Register';
import SignIn from './screens/SignIn';
import AboutUs from './screens/AboutUs';
import Listings from './screens/Listings';
import Donation from './screens/DonationPage';
import Auction from './screens/AuctionPage';
import Testimonials from './screens/Testimonials';
import AddTestimonial from './screens/AddTestimonial';
import EditTestimonial from './screens/EditTestimonial'
import AdminRescueForm from './screens/AdminDonationPage';
import AdminAuctionForm from './screens/AdminAuctionPage';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import PostForm from './screens/PostForm';
import ShowListing from './screens/ShowListing';
import AdminUserLits from './screens/AdminUserListPage';
import Profile from './screens/Profile';
import ContactUs from './screens/ContactUs';
import TOS from './screens/TOS';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Confirmation from './screens/Confirmation';
import AdPost from './screens/AdPost';
import ListItemEdit from './components/ListItemEdit';
import './CHAA.scss';
import AdminEditAboutUs from './screens/AdminEditAboutUs';
import AdminPanel from './screens/AdminPanel.js';
import AdminEditTOS from './screens/AdminEditTOS'
import AdminEditPrivacy from './screens/AdminEditPrivacy'
import AdminHomepageGallery from './screens/AdminHomepageGallery';
import AddBanner from './screens/AddBanner';
import AdminEditAds from './components/AdminEditAds';
import AdminEditBanner from './components/AdminEditBanner';

//<Route path='/show' component={ShowListing} />
function App() {
  return (
    /**
     *A container that contains different pages for the application.
     */
    <Router>
      <Header />
      <main>
        <Container>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/AboutUs' component={AboutUs} />
          <Route path='/Register' component={Register} />
          <Route path='/SignIn' component={SignIn} />
          <Route path='/Forgotpassword' component={ForgotPassword} />
          <Route path='/ResetPassword/:resetToken' component={ResetPassword} />
          <Route path='/Listings' component={Listings} />
          <Route path='/Listing/:_id' component={ShowListing} />
          <Route path='/DonationPage' component={Donation} />
          <Route path='/AdminAddRescueForm' component={AdminRescueForm} />
          <Route path='/PostForm' component={PostForm} />
          <Route path='/AdminUserListPage' component={AdminUserLits} />
          <Route path='/AuctionPage' component={Auction} />
          <Route path='/Testimonials' component={Testimonials} />
          <Route path='/AddTestimonial' component={AddTestimonial} />
          <Route path='/EditTestimonial' component={EditTestimonial} />
          <Route path='/AdminAuctionPage' component={AdminAuctionForm} />
          <Route path='/Profile' component={Profile} />
          <Route path='/ContactUs' component={ContactUs} />
          <Route path='/TOS' component={TOS} />
          <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
          <Route path='/Confirmation/:resetToken' component={Confirmation} />
          <Route path='/AdPost' component={AdPost} />
          <Route path='/ListItemEdit' component={ListItemEdit} />
          <Route path='/AdminEditAboutUs' component={AdminEditAboutUs} />
          <Route path='/AdminPanel' component={AdminPanel} />
          <Route path='/AdminEditTOS' component={AdminEditTOS} />
          <Route path='/AdminEditPrivacy' component={AdminEditPrivacy} />
          <Route path='/AdminHomepageGallery'component={AdminHomepageGallery} />
          <Route path='/AddBanner'component={AddBanner} />
          <Route path='/AdminEditAds'component={AdminEditAds} />
          <Route path='/AdminEditBanner'component={AdminEditBanner} />
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
