import axios from 'axios';
import { React } from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Listing from '../components/ListItems';
import { CircularProgress, Container, Grid } from '@material-ui/core';
import { RadioGroup, Radio, TextField, Typography, Slider, Paper, Stack, FormControl, FormControlLabel } from '@mui/material';

//Eventually add a way for users to show only ads from buyers,
//or to only show ads from sellers

/**
 * The listings page
 * @returns Listing page
 */
const Listings = () => {
  const history = useHistory();
  const location = useLocation();

  const params = location.search ? location.search : null;
  const [search, setSearch] = useState('');
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [sliderMax, setSliderMax] = useState(1000);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [priceOrder, setPriceOrder] = useState('');
  const [filter, setFilter] = useState('');
  const [sorting, setSorting] = useState('');
  const [province, setProvince] = useState('');
  const [sex, setSex] = useState('');

  const updateUIValues = (uiValues) => {
    setSliderMax(uiValues.maxPrice);

    if(uiValues.filtering.price) {
      let priceFilter = uiValues.filtering.price;

      setPriceRange([Number(priceFilter.gte), Number(priceFilter.lte)]);
    }

    if(uiValues.sorting.price) {
      let priceSort = uiValues.sorting.price;
      setPriceOrder(priceSort);
    }
  }

  useEffect(() => {
    let cancel;

    const fetchData = async () => {
      setLoading(true);
      try {
        let query;

        if(params && !filter) {
          query = params;
        }
        else {
          query = filter;
        }

        if(sorting) {
          if(query.length === 0) {
            query = `?sort=${sorting}`;
          }
          else {
            query = query + '&sort=' + sorting;
          }
        }

        if(province) {
          if(query.length === 0) {
            query = `?province=${province}`;
          }
          else {
            query = query + '&province=' + province;
          }
        }

        if(sex) {
          if(query.length === 0) {
            query = `?sex=${sex}`;
          }
          else {
            query = query + '&sex=' + sex;
          }
        }

        const { data } = await axios({
          method: "GET",
          url: `/posts${query}`,
          cancelToken: new axios.CancelToken((c) => cancel = c)
        });

        setItemData(data.data);
        setLoading(false);
        updateUIValues(data.uiValues);
      } catch (error) {
        if(axios.isCancel(error)) return;
      }
    }

    fetchData();
    return () => cancel();
  }, [filter, params, sorting, province, sex]);

  const handlePriceInputChange = (e, type) => {
    let newRange;
    
    if(type === 'lower') {
      newRange = [...priceRange];
      newRange[0] = Number(e.target.value);

      setPriceRange(newRange);
    }

    if(type === 'upper') {
      newRange = [...priceRange];
      newRange[1] = Number(e.target.value);

      setPriceRange(newRange);
    }
  }

  const onSliderCommitHandle = (e, newValue) => {
    buildRangeFilter(newValue);
  }

  const onTextFieldCommitHandler = () => {
    buildRangeFilter(priceRange);
  }

  const buildRangeFilter = (newValue) => {
    const urlFilter = `?price[gte]=${newValue[0]}&price[lte]=${newValue[1]}`;

    setFilter(urlFilter);
    history.push(urlFilter);
  }

  const handleSortChange = (e) => {
    setPriceOrder(e.target.value);

    if(e.target.value === 'ascending') {
      setSorting('price');
    }
    else if (e.target.value === 'descending') {
      setSorting('-price');
    }
  }

  /* category searches based off the field in the database
   * to add another field, you need to add another option
   * and a new post route needs to be created.
   */
  return (
    <Container>
      <Paper className='paper'>
        <Row>
          <input
            type='text'
            id='search'
            name='search'
            placeholder='Search...'
            onChange={(e) => {setSearch(e.target.value)}}
          />
        </Row>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>Filters</Typography>
            <div className='filter'>
              <Slider
                min={0}
                max={sliderMax}
                value={priceRange}
                valueLabelDisplay='auto'
                disabled={loading}
                onChange={(e, newValue) => setPriceRange(newValue)}
                onChangeCommitted={onSliderCommitHandle}
              />

              <div className='priceRangeInputs'>
                <TextField
                  size='small'
                  id='lower'
                  label='Min Price'
                  variant='outlined'
                  type='number'
                  disabled={loading}
                  value={priceRange[0]}
                  onChange={(e) => handlePriceInputChange(e, 'lower')}
                  onBlur={onTextFieldCommitHandler}
                />
                <TextField
                  size='small'
                  id='upper'
                  label='Max Price'
                  variant='outlined'
                  type='number'
                  disabled={loading}
                  value={priceRange[1]}
                  onChange={(e) => handlePriceInputChange(e, 'upper')}
                  onBlur={onTextFieldCommitHandler}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>Sort By</Typography>
            <FormControl component='fieldset' className='filter'>
              <RadioGroup
                aria-label='price-order'
                name='price-order'
                value={priceOrder}
                onChange={handleSortChange}
              >
                <FormControlLabel
                  value='descending'
                  disabled={loading}
                  control={<Radio />}
                  label='Price: Highest - Lowest'
                />
                <FormControlLabel
                  value='ascending'
                  disabled={loading}
                  control={<Radio />}
                  label='Price: Lowest - Highest'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <select 
              name='province'
              onChange={(e) => setProvince(e.target.value)}
              className='dropDown'
            >
              <option name='province' value=''>
                Pick a Province
              </option>
              <option name='province' value='MB'>
                Manitoba
              </option>
              <option name='province' value='BC'>
                British Columbia
              </option>
              <option name='province' value='AB'>
                Alberta
              </option>
              <option name='province' value='SK'>
                Saskatchewan
              </option>
              <option name='province' value='ON'>
                Ontario
              </option>
              <option name='province' value='QC'>
                Quebec
              </option>
              <option name='province' value='NB'>
                New Brunswick
              </option>
              <option name='province' value='NS'>
                Nova Scotia
              </option>
              <option name='province' value='PE'>
                PEI
              </option>
              <option name='province' value='NL'>
                Newfoundland and Labrador
              </option>
              <option name='province' value='YT'>
                Yukon
              </option>
              <option name='province' value='NT'>
                Northwest Territories
              </option>
              <option name='province' value='NU'>
                Nunavut
              </option>
            </select>

            <select 
              name='sex'
              onChange={(e) => setSex(e.target.value)}
              className='dropDown'
            >
              <option value=''>
                Pick a Sex
              </option>
              <option name='sex' value='Male'>
                Male
              </option>
              <option name-='sex' value='Female'>
                Female
              </option>
            </select>
          </Grid>
        </Grid>
      </Paper>
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
          {loading ? (
            <div className='loader'>
              <CircularProgress size='3rem' thickness={5}/>
            </div>
          ) : (
            itemData.filter((val) => {
              if(search === '') {
                return val;
              }
              else if(val.title.toLowerCase().includes(search.toLowerCase()) || 
                      val.city.toLowerCase().includes(search.toLowerCase()) ||
                      val.description.toLowerCase().includes(search.toLowerCase())) {
                return val;
              }
            }).map(itemData => (
              <div key={itemData._id}>
                <Listing data={itemData} />
              </div>
            ))
          )}
      </Stack>
    </Container>
  );
};

export default Listings;
