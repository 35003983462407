import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const AdminEditAboutUsForm = () => { 
    let history = useHistory();

    const [Title, setTitle] = useState('');
    const [Statement, setStatement] = useState('');
    const [Description, setDescription] = useState('');
    const [ErrorMsg, setErrorMsg] = useState('');

    useEffect(async () => {
        await axios.get(`/aboutus`).then((response) => {
            let aboutus = (response.data[0]);
            setTitle(aboutus.title);
            setStatement(aboutus.statement);
            setDescription(aboutus.description);
        });
    }, []);

    const confirmEdit = async (e) => {
        e.preventDefault();

        try {
            if(Title === '') {
                throw new Error('Please add a title');
            }
            else if (Statement === '') {
                throw new Error('Please add a mission statement');
            }
            else if (Description === '') {
                throw new Error('Please add a description');
            }
            else {
                await axios.patch(`/aboutus/edit`, {
                    title: Title,
                    statement: Statement,
                    description: Description,
                }); 
            }    

            history.push(`/aboutus`);
            window.location.reload();
        }
        catch (error){
            setErrorMsg(error);
        }
    };

    const cancelEdit = (e) => {
        e.preventDefault();
        history.push(`/aboutus`);
        window.location.reload();
    };

    return (
        <div>
            <Row>
            <h1>Edit About Us</h1>
            </Row>
            <Card>
                <Col id='editAboutUs'>
                    <Row className='editAboutUs_field'>
                        Title:
                        <input
                            name='title'
                            value={Title}
                            placeholder='title'
                            onChange={(e) => setTitle(e.target.value)}
                        ></input>
                    </Row>
                    <Row className='editAboutUs_field'>
                        Mission Statement:
                        <input
                            name='statement'
                            value={Statement}
                            placeholder='mission statement'
                            onChange={(e) => setStatement(e.target.value)}
                        ></input>
                    </Row>
                    <Row id='editAboutUs_description'>
                        Description:
                        <textarea
                            name='description'
                            value={Description}
                            placeholder='description'
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        <label className='errorMessage'>{ErrorMsg.toString()}</label>
                    </Row>
                    <Row id='editAboutUs_button'>
                        <button onClick={(e) => cancelEdit(e)}>Cancel</button>
                        <button onClick={(e) => confirmEdit(e)}>Confirm</button>
                    </Row>
                </Col>
            </Card>
        </div>
    );
};

export default AdminEditAboutUsForm;