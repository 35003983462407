import * as React from 'react';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { Box, Paper, Stack, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';



/**The Testimonial page */
const Testimonials = () => {

    const [list, setList] = useState([]);
    const history = useHistory();
    let isSignIn = sessionStorage.getItem('isSignIn');
    const [loading, setLoading] = useState(false);
    let userType = sessionStorage.getItem('userType');

    //This will fetch all testimonials from the Mongo Database
    useEffect(async () => {
        setLoading(true)
        await Axios.get('/list').then((response) => {
            setList(response.data);
            
        });
        setLoading(false)
    }, []);
    
    // When the add testimonial button is clicked, it will redirect the user 
    // to a new where they can create a testimonial
    const routeChange = () =>{ 
      let path = 'AddTestimonial'; 
      history.push(path);
    }

    const deleteTestimonial = (id) => {
        Axios.delete(`/testimonial/delete/${id}`);
        window.location.assign('/Testimonials');
        } 

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffefe4',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        }));


    return (
        <>
        <div className='TestimonialsTitle'>
        {isSignIn === 'true' && userType === 'Admin' ? (
            <div id='TestimonialButton'> 
                <button type="button" onClick={routeChange}>
                    Create New Testimonial
                </button> 
                
            </div>
            ):(
            ''
        )}
            <h1>Testimonials</h1>
            <p>Here are some of the people that got their horses from us and now the horses are doing better.</p>
        </div>
            
            {/* This will display all testimonial */}
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2}>
                {loading ? 
            (
                <div className='loading'>
                    <CircularProgress />
                </div>
            ) : (list.map((val, key) =>{
                return (
                    <Item className='customerTestimonials'>
                    {isSignIn === 'true' && userType === 'Admin' ? (
                    <li id='edit'><Link to={{ pathname: `/EditTestimonial/${val._id}`, state:{id: val._id, title: val.title, testimonial: val.testimonial, name: val.name}}}>Edit</Link></li>
                    ) : (
                        ''
                    )}
                    {isSignIn === 'true' && userType === 'Admin' ? (
                        <Button
                            onClick={() => {deleteTestimonial(val._id);}}
                            className='DeleteButton'
                            type='button'
                        >
                            Delete
                        </Button>
                        ) : (
                        ''
                    )}
                        <Stack  
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <div id='firstImg'>
                            <img src={val.imageUrl} />
                        </div>
                        <h4>{val.title} </h4>
                        <p> {val.testimonial}</p> 
                        <div id='secImg'>
                            <img src={val.imageUrlTwo} />
                        </div>
                    </Stack>
                        <p>- {val.name} </p>
                    </Item>
                );
            }))}
                </Stack>
            </Box>
        </>
    );
};

export default Testimonials;
