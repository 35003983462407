import React, { useState, useEffect } from 'react';
import AdminUserInfoList from '../components/AdminUserInfoList';
import axios from 'axios';

/**
 * Gets the data that the Admin User List to bring into the Admin User List Page.
 * @returns The AdminUserInfoList component.
 */
const AdminUserList = () => {
  const [usersListData, setUsersData] = useState([]);

  useEffect(async () => {
    let userData = await axios.get('/users');

    setUsersData(userData.data);
  }, []);
  return usersListData.map((items) => {
    return <AdminUserInfoList data={items} />;
  });
};

export default AdminUserList;
