import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container } from 'react-bootstrap';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import Logo from '../images/FullColor_CHAA.jpg';
import { Avatar } from '@mui/material';

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  confirmed: '',
  userType: '',
  imageUrl: '',
};

const bannerState = {
  imageUrl: '',
};

//If there is no userData, set it to the inital state
//Should only run on first load of the website, stops things that require user data from breaking
if (!sessionStorage.getItem('userData')) {
  sessionStorage.setItem('userData', JSON.stringify(initialState));
}

const Header = ({ history }) => {
  const [banner, setBanner] = useState(bannerState);
  const [type, setType] = useState('');
  const [getProfile, setProfile] = useState(initialState);
  let id = sessionStorage.getItem('authToken');

  /**
   * When logging out removes all session variables and sets isSignIn to false.
   */
  const logoutHandler = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('userType');
    sessionStorage.removeItem('isSignIn');
    sessionStorage.setItem('isSignIn', false);
    sessionStorage.removeItem('userType');
    sessionStorage.setItem('userData', JSON.stringify(initialState));
    history.push('/login');
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 60, 
        height: 60,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  /**
   * Variables to check if signed in and the type of user is logged in.
   */
  let isSignIn = sessionStorage.getItem('isSignIn');
  let userType = sessionStorage.getItem('userType');

  /**
   * Gets all the ads and sets the state of ads.
   */
  useEffect(async () => {
    let response = await axios.get('/Banner');
    setBanner(response.data);
  }, []);

  /**
   * Gets a user profile and sets it to state.
   */
   useEffect(async () => {
    let profile = await axios.get(`User/${id}`);
    setProfile(profile.data);
  }, []);

  /**
   * Picks a random number from 0 to length of ads array.
   */
  let randomIndex = Math.floor(Math.random() * banner.length);

  /**
   * Checks if ads is undefined if is sets it.
   */
  if (banner[randomIndex] === undefined) {
    bannerState.imageUrl = 'www.chaa.com';
  }

  return (
    <header>
      {banner[randomIndex] === undefined ? (
        <h6>loading banner...</h6>
      ) :  (
        <div className='banner'>
          <img src={banner[randomIndex].imageUrl}/>
        </div>
      )}
     <Navbar expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img className='Logo' src={Logo}></img>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto navBar'>
              <LinkContainer to='/Listings'>
                <Nav.Link>Listings</Nav.Link>
              </LinkContainer>
              {isSignIn === 'true' ? (
                ''
              ) : (
                <LinkContainer to='/Register'>
                  <Nav.Link>Register</Nav.Link>
                </LinkContainer>
              )}
              {isSignIn === 'true' ? (
                ''
              ) : (
                <LinkContainer to='/SignIn'>
                  <Nav.Link>Sign In</Nav.Link>
                </LinkContainer>
              )}
              {isSignIn === 'true' ? (
                <LinkContainer to='/PostForm'>
                  <Nav.Link>Post AD</Nav.Link>
                </LinkContainer>
              ) : (
                ''
              )}
              <LinkContainer to='/DonationPage'>
                <Nav.Link>Donation</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/AuctionPage'>
                <Nav.Link>Auctions</Nav.Link>
              </LinkContainer>

              <LinkContainer to='/Testimonials'>
                <Nav.Link>Testimonials</Nav.Link>
              </LinkContainer>

              {isSignIn === 'true' ? (
                <LinkContainer to='/Profile'>
                  <Nav.Link>Profile</Nav.Link>
                </LinkContainer>
              ) : (
                ''
              )}

              {isSignIn === 'true' && userType === 'Admin' ? (
                <LinkContainer to='/AdminPanel'>
                  <Nav.Link>Admin Panel</Nav.Link>
                </LinkContainer>
              ) : (
                ''
              )}    
              
              {isSignIn === 'true' ? (
                <LinkContainer to='/'>
                  <Nav.Link onClick={logoutHandler}>Log Out</Nav.Link>
                </LinkContainer>
              ) : (
                ''
              )}

            </Nav>
          </Navbar.Collapse>
          {/* If the user hs a profile, it will diplay it. */}
          {isSignIn === 'true' ? (
                 <div className='profilePic'>
                   <p>Welcome, {getProfile.firstName}</p>
                   { getProfile.imageUrl !== '' ? (
                    <LinkContainer to='/Profile'>
                      <Nav.Link>
                        <Tooltip title={getProfile.firstName}>
                          <Avatar className='avatar'
                            alt='Users Profile'
                            src={getProfile.imageUrl} 
                            sx={{ 
                              width: 60, 
                              height: 60,
                            }}
                          />
                        </Tooltip>
                      </Nav.Link>
                    </LinkContainer>
                   ) : (
                    <LinkContainer to='/Profile'>
                    <Nav.Link>
                      <Tooltip title={getProfile.firstName}>
                        <Avatar className='avatar'
                          {...stringAvatar(`${getProfile.firstName} ${getProfile.lastName}`)}
                        />
                      </Tooltip>
                    </Nav.Link>
                  </LinkContainer>
                   )
                   }
                 </div>
              ) : (
                ''
              )}    
         
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
