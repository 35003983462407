import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import FB from './../images/FB.png';
import instagram from '../images/Instagram.png';
import tikTok from '../images/TikTok.png';
import { useState, useEffect } from 'react';
import axios from "axios";

/**
 * Footer of the page with social media links, as well as the terms of services, about us, and contact us pages.
 */
const Footer = () => {

  const [ads, setAds] = useState('');
  const [index, setIndex] = useState([]);

   /**
   * Gets all the ads and sets the state of ads.
   */
    useEffect(async () => {
      let response = await axios.get('/Ads');
      setAds(response.data);
    }, []);
    
    useEffect(async () => {
    let adindexes = [];
    if (ads.length === 0 ) return;

    if (ads.length <= 2) {
        let random1 = Math.round(Math.random() * (ads.length -1));
        let random2 = Math.round(Math.random() * (ads.length -1));
        adindexes = [random1, random2];
    }
    else {
        do {
          let random = Math.round(Math.random() * (ads.length -1));
            let foundindex = false;
            for (let i = 0; i < adindexes.length; i++) {
                if (adindexes[i] == random) foundindex = true;
            }
            if (!foundindex)
                adindexes.push(random);
        } while (adindexes.length < 2);
      }
      setIndex(adindexes)
        }, [ads]);

    /**
   * Picks a random number from 0 to length of ads array.
   */
   let randomIndex = Math.floor(Math.random() * ads.length);

   let randomIndex2 = Math.floor(Math.random() * ads.length);

  return (
    <footer className='mx-auto'>
      <Row className='footer'>
        <Col className='socialMedia'>
          <a
            target='_blank'
            href='https://www.facebook.com/groups/2576726095971388/'
            rel='noreferrer'
          >
            <img src={FB} alt='Facebook'></img>
          </a>
          <a
            target='_blank'
            href='https://www.instagram.com/canada_horse_auction/?r=nametag'
            rel='noreferrer'
          >
            <img src={instagram} alt='Instagram'></img>
          </a>
          <a
            target='_blank'
            href='https://www.tiktok.com/@canadahorse?_d=secCgYIASAHKAESMgowE8mGxU%2FDq2wBg7Ih%2Fh12oPZNm0OreDF18hODiXJXXItcbA4d3mTEpv4vRnaDwMEKGgA%3D&_r=1&language=en&sec_uid=MS4wLjABAAAABMr1BHC-JVd4P0xH-E0vRJ20-gGFcAKssPrbFvux2lalpGCGiWcLco8Lm0J0FPbw&sec_user_id=MS4wLjABAAAABMr1BHC-JVd4P0xH-E0vRJ20-gGFcAKssPrbFvux2lalpGCGiWcLco8Lm0J0FPbw&share_app_id=1233&share_author_id=6847548990906942470&share_link_id=F7329BA3-2687-430D-B9EA-E7D590746CF8&source=h5_m&tt_from=copy&u_code=ddafk86jda3949&user_id=6847548990906942470&utm_campaign=client_share&utm_medium=ios&utm_source=copy'
            rel='noreferrer'
          >
            <img src={tikTok} alt='TikTok'></img>
          </a>
        </Col>
        <Col>
          <LinkContainer to='/AboutUs'>
            <Nav.Link>
              <h6>
                <small>About Us</small>
              </h6>
            </Nav.Link>
          </LinkContainer>
        </Col>
        <Col>
          <LinkContainer to='/ContactUs'>
            <Nav.Link>
              <h6>
                <small>Contact Us</small>
              </h6>
            </Nav.Link>
          </LinkContainer>
        </Col>
        <Col>
          <LinkContainer to='/TOS' className='pl-5'>
            <Nav.Link>
              <h6>
                <small>Terms Of Services</small>
              </h6>
            </Nav.Link>
          </LinkContainer>
        </Col>
      </Row>
      <div className='footerContainer'>
        {(index.length > 0) ? (

          (ads[randomIndex] !== undefined) ? (
            <div className='ads'>
              <img src={ads[index[0]].imageUrl}/>
            </div>
          ) : (
            <h6>loading ads...</h6>
          )
        ) : (
          <h6>loading ads...</h6>
        )
      }

      {(index.length > 0) ? (

          (ads[randomIndex2] !== undefined) ? (
            <div className='ads2'>
              <img src={ads[index[1]].imageUrl}/>
            </div>
          ) : (
            <h6>loading ads...</h6>
          )
          ) : (
          <h6>loading ads...</h6>
          )

        }
      </div>
    </footer>
  );
};

export default Footer;
