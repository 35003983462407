import React from 'react';
import AdminRescueForm from '../components/AdminAddRescueForm.js';

/**
 * Grabs the AdminAddRescueForm.js to show on the screen. This id the form where the admin can add a new rescue group to the page.
 * @returns The AdminAddRescueForm commponent.
 */
const AdminDonationPage = () => {
  return (
    <div>
      <AdminRescueForm />
    </div>
  );
};

export default AdminDonationPage;
