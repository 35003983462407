import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

/**
 * Form to reset a user password.
 */
const ResetPassword = ({ match, history }) => {
  const [rePassword, setRePassword] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  /**
   * Sets header for axios.post
   */
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  /**
   * Checks the visibility of password field.
   */
  const displayPassword = () => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  /**
   * On submit resets user password.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    /**
     * Checks if both password fields match, and if password meets password criteria for security.
     */
    if (password !== rePassword) {
      setError('Password do not match');
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError('Password do not match');
    } else if (password.length < 8) {
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError('Password must contain a minimum of 8 characters');
    } else if (password.length < 8) {
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError('Password must contain a minimum of 8 characters');
    } else if (password.length > 32) {
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError('Password can not contain more than 32 characters');
    } else if (password.search(/[A-Z]/) < 0) {
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError(
        'Password must contain at least one upper case character'
      );
    } else if (password.search(/[a-z]/) < 0) {
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError(
        'Password must contain at least one lower case character'
      );
    } else if (password.search(/[0-9]/) < 0) {
      setTimeout(() => {
        setError('');
      }, 5000);
      return setError('Password must contain at least one number');
    } else {
      /**
       * If reset token matches and password meets minimum security criteria then resets the user password.
       */
      try {
        await axios.put(
          `/ResetPassword/${match.params.resetToken}`,
          {
            password,
          },
          config
        );

        history.push('/SignIn');
      } catch (error) {
        setError(error);

        setTimeout(() => {
          setError('');
        }, 5000);
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col></Col>
          <Col md={4} className='my-5'>
            <Col>
              <Row className='my-4'>
                <h1> Reset Password </h1>
              </Row>
              <Row className='my-3'>
                <input
                  useref='password'
                  type={isVisible ? 'text' : 'password'}
                  placeholder='Enter Password'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Row>
              <Row className='my-3'>
                <input
                  useref='RePassword'
                  type={isVisible ? 'text' : 'password'}
                  placeholder='Re - Enter Password'
                  onChange={(e) => setRePassword(e.target.value)}
                  value={rePassword}
                />
              </Row>
              <input type='checkbox' onChange={displayPassword} />
              <label
                htmlFor='checkbox-input'
                className='round-slider-container'
              >
                Show Password
              </label>
              <Row className='my-3'>
                <Button className='btn-block' type='submit'>
                  Reset Password
                </Button>
              </Row>
              <Row>
                {error && <span className='errorMessage'>{error} </span>}
              </Row>
              <Row>
                <Row className='my+5'>
                  <h6>
                    <small>
                      <Link to='/Register'>
                        Don't have an account? Click here to create an account
                      </Link>
                    </small>
                  </h6>
                </Row>
              </Row>
            </Col>
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
};

export default ResetPassword;
