import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const AuctionListing = ({ data }) => {
  const { organization, description, province, date, auctionLink, image, _id } =
    data;

  const deleteAuction = async () => {
    if (window.confirm('Did you want to remove this Auction?')) {
      try {
        await axios.delete(`/auctions/delete/${_id}`);
        window.location.assign('/AuctionPage');
      } catch (error) {
      }
    }
  };

  /**
   * Session variable being saved when a user logins into the website and keeps their status as signed in.
   */
  let isSignIn = sessionStorage.getItem('isSignIn');
  /**
   * Session variable being saved of their user type.
   */
  let userType = sessionStorage.getItem('userType');

  return (
    <>
      <Form onSubmit={deleteAuction} className='auctionGroup'>
        <Card>
          <Card.Title>
            {organization}
            {isSignIn === 'true' && userType === 'Admin' ? (
              <Button
                onClick={deleteAuction}
                className='DeleteButton'
                type='button'
              >
                Delete
              </Button>
            ) : (
              ''
            )}
          </Card.Title>
          <Card.Body>
            <Col>
              <Card.Text>Located in {province}</Card.Text>
              <Card.Text> {description} </Card.Text>
              <Card.Text> {date}</Card.Text>
            </Col>
            <Col id='auctionLinks'>
              <img src={image} />
              <Card.Text>
                <a target='_blank' href={auctionLink} rel='noreferrer'>
                  Visit their site
                </a>
              </Card.Text>
            </Col>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

AuctionListing.propTypes = {
  listing: PropTypes.object,
};

export default AuctionListing;
