import React from 'react';

import Gallery from '../components/HomepageGallery';

const HomeScreen = () => {
  
  return (
    <>
      <h1>Welcome To Canada Horse Auction Alternative</h1>

      <Gallery />
    </>
  );
};

export default HomeScreen;
