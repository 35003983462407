import React from 'react';
import AdminEditAboutUsForm from '../components/AdminEditAboutUsForm.js';


const AdminEditAboutUs = () => {
  return (
    <div>
      <AdminEditAboutUsForm />
    </div>
  );
};

export default AdminEditAboutUs;

