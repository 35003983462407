import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../Visuals/_ContactUs.scss';

//Eventually make it so that the subject of the email is decided by a dropdown menu with options for various issues

const ContactUs = () => {
  /**
   * Sets header configuration for axios.
   */
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  /**
   * initial state of form data.
   */
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    concern: '',
  };

  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState('');

  /**
   * On input fields updates state when it is changed.
   */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * On submit sends an email to CHAA email with users email and their concern.
   */
  const handleSubmit = async (e, res) => {
    try {
      e.preventDefault();
      let concern = formData.concern;
      let email = formData.email;

      /**
       * Checks if fields are empty and if email exists in database, then sends an email with user concern to chaa34189@gmail.com.
       * If fields are empty and error will be shown for 5000 seconds.
       */
      if (email === '') {
        setTimeout(() => {
          setError('');
        }, 5000);
        setError('Fields are empty');
      } else if (concern === '') {
        setTimeout(() => {
          setError('');
        }, 5000);
        setError('Concern field is empty');
      } else {
        await axios.post('/Node', { email, concern, config });
        setTimeout(() => {
          setError('');
          window.location.reload();
        }, 5000);
        setError('Thank you ');
      }
    } catch (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
      setError('You have to be a user to sent a concern or use the email you signed up with');
    }
  };

  return (
    <Form onSubmit={handleSubmit} className='inputArea' id='contactUs'>
      <h2>Feel Free To Contact Us</h2>
      <Row>
        <Col>
          <label>First Name:</label>
          <input
            name='firstName'
            type='text'
            placeholder='First Name'
            onChange={handleChange}
          />
        </Col>
        <Col>
          <label>Last Name:</label>
          <input
            name='lastName'
            type='text'
            placeholder='Last Name'
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <label htmlFor='email'>
          Email Address (<Link to='/PrivacyPolicy'>Privacy Policy</Link>)
        </label>
        <input
          id='ContactUsEmail'
          name='email'
          type='email'
          placeholder='Email Address'
          onChange={handleChange}
        />
      </Row>
      <Row>
        <label>Enter Your Concern:</label>
        <textarea
          name='concern'
          type='text'
          onChange={handleChange}
          maxLength='5000'
        ></textarea>
      </Row>

      <span className='errorMessage'>{error}</span>
      <Button type='submit'>Contact Us</Button>
    </Form>
  );
};

export default ContactUs;
