import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import '../Visuals/_ListItems.scss';

/**
 * The Listing page listing items
 * link tag goes to ShowListing.js
 * @param {various listing item fields} data
 * @returns the listItem on the listing page.
 */
const ListItems = ({ data }) => {
  const { _id, title, description, imageUrl, price } = data;

  return (
    <>
      <Row className='ListItem'>
        <Card onClick={() => itemClicked(_id)}>
          <Link to={{ pathname: `/Listing/${_id}` }}>
            <Card.Title>{'Title:' + title}</Card.Title>
            <Col>
              <Card.Body>
                <img src={imageUrl} />
                <Card.Text id='listItemPrice'>{'Costs: ' + price}</Card.Text>
                <Card.Text id='listItemDescription'>{description}</Card.Text>
              </Card.Body>
            </Col>
          </Link>
        </Card>
      </Row>
    </>
  );
};

/**
 * controls the onClick event of the card.
 * @param {the ID of the listing item} id
 */
function itemClicked(id) {
  sessionStorage.removeItem('ListItemId');
  sessionStorage.setItem('ListItemId', id);
}

ListItems.propTypes = {
  listing: PropTypes.object,
};

export default ListItems;
