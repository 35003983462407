import React from 'react';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

/**
 * The terms of services page
 */
const TOS = () => {

  const [TOS, setTOS] = useState([]);

  useEffect(async () => {
    await Axios.get('/termsofservice').then((response) => {
        setTOS(response.data[0]);
    });
}, []);

  return (
    <>
      <h1>TERMS OF USE</h1>
      { parse(`${TOS.tos}`)  }
      {/* <div className='ClassifiedAds'>
        <p>
          <strong>
            Classified Ads are FREE for non-commercial and personal items.
          </strong>{' '}
         
        </p>
      </div>
      <div className='Classifieds'>
        <p>
          <strong>
            When using the Classifieds at Canadahorseauctionalternative.com,
          </strong>{' '}
          always post under the most suitable category, and never:
          <ul>
            <li>
              violate anyone's rights, violate any laws, or violate our{' '}
              <Link to='/PrivacyPolicy'>Prohibited Content Policy</Link>
            </li>
            <li>be deceitful or dishonest</li>
            <li>spread viruses, spam, chain letters, or pyramid schemes</li>
            <li>upload an excessive amount of information</li>
            <li>duplicate, alter, and circulate anyone else's posts</li>
            <li>
              use Canadahorseauctionalternative.com to collect content and
              contact information
            </li>
            <li>bypass measures we've put in place to limit access</li>
          </ul>
        </p>
      </div>
      <div className='Misuse'>
        <p>
          <strong>Misuse of Canadahorseauctionalternative.com.</strong>To help
          keep the site working smoothly, use the{' '}
          <strong>Report This Ad</strong> button beside the ad in question to
          alert us to any troublesome or disrespectful subject matter. We do not
          accept responsibility for supervising the Classifieds at
          canadahorseauctionalternative.com or for unsanctioned or illegal
          subject matter. If your ad violates any law or policy, it will be
          removed.
        </p>
      </div>
      <div className='Cost'>
        <p>
          <strong>Cost. </strong> The Classifieds at
          Canadahorseauctionalternative.com are completely free, but you do have
          the option to purchase to “feature” your ad.
        </p>
      </div>
      <div className='Content'>
        <p>
          <strong>Content. </strong>
          {TOS.content}
        </p>
      </div>
      <div className='Intellectual'>
        <p>
          <strong>Intellectual Property Infringements. </strong>
          {TOS.infridgement}
        </p>
      </div>
      <div className='Liability'>
        <p>
          <strong>Liability. </strong>
          {TOS.liability}
        </p>
      </div>
      <div className='PersonalInformation'>
        <p>
          <strong>Personal Information. </strong>By using the Classifieds at
          Canadahorseauctionalternative.com, you're agreeing to the
          accumulation, transmission, archiving, and utilization of your
          personal information by the data controller on Canadian servers as
          further described in our{' '}
          <Link to='/PrivacyPolicy'>Privacy Policy.</Link>
        </p>
      </div> */}
    </>
  );
};

export default TOS;
