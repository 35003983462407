//axios get request using the given post id
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ShowListItem from '../components/ShowListItem';

/**
 * shows a listing page based on it's ID
 * @returns the listitem's page (ShowListItem).
 */
const ShowListing = () => {
  //useStates
  const [itemData, setItemData] = useState([]);
  const [listerData, setListerData] = useState([]);

  let id = sessionStorage.getItem('ListItemId');

  useEffect(async () => {
    //ListerData uses listingData to get it's info.
    let listingData = await axios.get(`/posts/${id}`);
    let listerData = await axios.get(`/User/${listingData.data.user}`);

    setItemData(listingData.data);
    setListerData(listerData.data);
  }, []);
  /*the listing id is required for editing, which is passed in via data, however
   * both item and lister data share a name for the id, so we're passing two(2) variables
   */
  return <ShowListItem data={itemData} lister={listerData} />;
};

export default ShowListing;
