import React, { useState, useEffect } from 'react';
import AuctionListing from '../components/AuctionListing';
import axios from 'axios';
/**
 * Gets the list of AuctionListing to bring into the Auction Page.
 * @returns The AuctionListing component.
 */
const AuctionPage = () => {
  const [auctionGroupData, setAuctionData] = useState([]);

  useEffect(async () => {
    let auctionData = await axios.get('/auctions');

    setAuctionData(auctionData.data);
  }, []);

  return auctionGroupData.map((items) => {
    return <AuctionListing data={items} />;
  });
};
<h1>Auctions</h1>;

export default AuctionPage;
