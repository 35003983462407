import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { CircularProgress, Button, TextareaAutosize } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

const EditTestimonial = (data) => {
    const { title, testimonial, name, id } = data.location.state;

    const [Title, setTitle] = useState(title);
    const [Content, setContent] = useState(testimonial);
    const [Author, setAuthor] = useState(name);
    const [ErrorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const timer = React.useRef();
    let history = useHistory();

    // let isSignIn = sessionStorage.getItem('isSignIn');
    // let userType = sessionStorage.getItem('userType');

    useEffect(() => {
        return () => {
          clearTimeout(timer.current);
        };
      }, []);
    
    const editTestimonial = async (e) => {
        e.preventDefault();

        if (!loading) {
            setSuccess(false);
            setLoading(true);
            timer.current = window.setTimeout(() => {
              setSuccess(true);
              setLoading(false);
            }, 2000);
          }

        try {
            if (Title === ' ') {
              throw new Error('Please add a title');
            } else if (Content === ' ') {
              throw new Error('Please add a testimonial');
            } else if (Author === ' ') {
              throw new Error('Please add a name');
            } else {
              await Axios.patch(`/testimonial/${id}`, {
                title: Title,
                testimonial: Content,
                name: Author,
              });
      
              history.push(`/Testimonials`);
              window.location.reload();
            }
          } catch (error) {
           //displays the error in text
           setErrorMsg(error);
          }

        }

        const cancelEdit = (e) => {
            e.preventDefault();
            history.push(`/Testimonials`);
            window.location.reload();
          };


    return (
        <>
            <Form className='inptAreaTestimonial'>
                <Row className='my-4'>
                    <h1> Edit Testimonial </h1>
                </Row>
                <div className='addTestimonial'>
                    <label for="title">Title:</label>
                    <Row className='my-3'>
                        <input 
                            type="text" 
                            placeholder="Title" 
                            name="title"
                            onChange={(e) => setTitle(e.target.value)}
                            value={Title}>
                        </input>
                    </Row>

                    <label for="contents">Contents:</label>
                    <Row className='my-3'>
                        <TextareaAutosize  
                            type="text" 
                            placeholder="Contents"
                            maxRows={3}
                            name="contents"
                            onChange={(e) => setContent(e.target.value)}
                            value={Content}>
                        </TextareaAutosize>

                    </Row>
                    <label for="author">Author:</label>
                    <Row className='my-3'>
                        <input 
                            type="text" 
                            id='author' 
                            placeholder="Author" 
                            name="author"
                            onChange={(e) => setAuthor(e.target.value)}
                            value={Author}>
                        </input>
                    </Row>
                    <Button variant="contained" onClick={(e) => cancelEdit(e)}  type='submit'>
                        CANCEL
                    </Button>
                    <Button variant="contained" disabled={loading} onClick={(e) => editTestimonial(e)} type='submit'>
                        UPDATE
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '552px',
                                marginLeft: '32px',
                            }}
                        />
                        )
                    }
                </div>
            </Form>
        </>
    );
};

EditTestimonial.propTypes = {
    testimonials: PropTypes.object,
  };

export default EditTestimonial;