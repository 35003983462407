import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

/**
 * initial state of form data.
 */
const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  repassword: '',
  phoneNumber: '',
};

const Register = ({ history }) => {
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  /**
   * On submit creates a new user and saves information to database.
   * As well as sending an account confirmation to registered email.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');

    /**
     *Checks if both passwords match and if password is less than 8 or greater than 32 characters.
      Or if it contains upper/lower case characters and if it contains numbers.
     */
    if (formData.password !== formData.repassword) {
      setFormData.password = '';
      setFormData.repassword = '';
      setTimeout(() => {
        setError('');
      }, 5000);
      setError('Passwords do not match');
    } else if (formData.password.length < 8) {
      setTimeout(() => {
        setError('');
      }, 15000);
      setError('Password must contain a minimum of 8 characters');
    } else if (formData.password.length > 32) {
      setTimeout(() => {
        setError('');
      }, 15000);
      setError('Password can not contain more than 32 characters');
    } else if (formData.password.search(/[A-Z]/) < 0) {
      setTimeout(() => {
        setError('');
      }, 15000);
      setError('Password must contain at least one upper case character');
    } else if (formData.password.search(/[a-z]/) < 0) {
      setTimeout(() => {
        setError('');
      }, 15000);
      setError('Password must contain at least one lower case character');
    } else if (formData.password.search(/[0-9]/) < 0) {
      setTimeout(() => {
        setError('');
      }, 15000);
      setError('Password must contain at least one number');
    } else if (formData.firstName === '' || formData.lastName === '') {
      setError('First and Last Name Should be filled');
      setTimeout(() => {
        setError('');
      }, 15000);
    } else {
      /**
       * Creates a new user and uploads the data to the database and sends an email to the user to confirm their new account.
       */
      try {
        await axios.post('/Users', formData, config);
        await axios.post('/Confirm', { email: formData.email }, config);
        setError(
          `Email was sent to ${formData.email} to confirm account. Check Spam if not found.`
        );
        setTimeout(() => {
          setError('');
          history.push('/SignIn');
        }, 20000);
        setFormData(initialState);
      } catch (error) {
        setError(error.response.data.error);
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    }
  };

  /**
   * When input field is changed it updates the state of form data.
   */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * Checks the visibility of password field
   */
  const displayPassword = () => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className='inputArea'>
        <Row className='my-4'>
          <h1> Sign Up </h1>
        </Row>
        <Row className='my-3'>
          <input
            name='firstName'
            type='text'
            placeholder='First Name'
            onChange={handleChange}
            value={formData.firstName}
            autoFocus
          />
        </Row>

        <Row className='my-3'>
          <input
            name='lastName'
            type='text'
            placeholder='Last Name'
            value={formData.lastName}
            onChange={handleChange}
          />
        </Row>
        <Row className='my-3'>
          <input
            name='email'
            type='email'
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
          />
        </Row>
        <Row className='my-3'>
          <input
            name='password'
            type={isVisible ? 'text' : 'password'}
            placeholder='Enter Password'
            value={formData.password}
            onChange={handleChange}
          />
        </Row>
        <Row className='my-1'>
          <input
            name='repassword'
            type={isVisible ? 'text' : 'password'}
            placeholder='Re - Enter Password'
            value={formData.repassword}
            onChange={handleChange}
          />
        </Row>
        <Row className='my-3'>
          <input
            type='tel'
            name='phoneNumber'
            placeholder='(Optional) Phone Number no spaces'
            pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
            maxLength='12'
            title='format(eg: 0001112222) no spaces'
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <span className='errorMessage'>{error}</span>
          <Col>
            <input type='checkbox' onChange={displayPassword} />
            <label htmlFor='checkbox-input' className='round-slider-container'>
              Show Password
            </label>
          </Col>
        </Row>
        <Row>
          <Button className='btn-block' type='submit'>
            SIGN UP
          </Button>
        </Row>
        <Row className='my-3'>
          <h6>
            <small>
              <Link to='/SignIn'>Already have an Account? Sign in</Link>
            </small>
          </h6>
        </Row>
      </Form>
    </>
  );
};

export default Register;
