import React from "react";
import { LinkContainer } from "react-router-bootstrap";

const AdminPanel = () => {
    return (
        <div>
            <h1 id='Adminheader'>Admin Panel</h1>

            {/* Add more buttons as needed */}
            <div id='panelButtons'>
                <LinkContainer to='/AdPost'>
                    <button>Ad Post</button>
                </LinkContainer>

                <LinkContainer to='/AdminEditAds'>
                    <button>Edit Ads</button>
                </LinkContainer>

                <LinkContainer to='/AddBanner'>
                    <button>Upload Banner</button>
                </LinkContainer>

                <LinkContainer to='/AdminEditBanner'>
                    <button>Edit Banner</button>
                </LinkContainer>

                <LinkContainer to='/AdminUserListPage'>
                    <button>Admin List Users</button>
                </LinkContainer>

                <LinkContainer to='/AdminAddRescueForm'>
                    <button>Donation Admin Form</button>
                </LinkContainer>

                <LinkContainer to='/AdminAuctionPage'>
                    <button>Auctions Admin Form</button>
                </LinkContainer>

                <LinkContainer to='/AdminEditAboutUs'>
                    <button>Edit About Us</button>
                </LinkContainer>

                <LinkContainer to='/AdminEditTOS'>
                    <button>Edit Term Of Service</button>
                </LinkContainer>

                <LinkContainer to='/AdminEditPrivacy'>
                    <button>Edit Privacy Policy</button>
                </LinkContainer>

                <LinkContainer to='/AdminHomepageGallery'>
                    <button>Edit Home Page Image</button>
                </LinkContainer>
            </div>
        </div>
    );
};

export default AdminPanel;