import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Axios from 'axios';

const AddTestimonial = () => {

    const initialFormData = {
        user: JSON.parse(sessionStorage.getItem('userData')).id,
        imageUrl: '',
      };

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [author, setAuthor] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageTwo, setSelectedImageTwo] = useState('');
    const [formData, setFormData] = useState(initialFormData);
    const history = useHistory();

    const url =
   'https://api.cloudinary.com/v1_1/canada-horse-auction-alternative/image/upload';

   const cloudConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const { imageUrl, imageUrlTwo } = formData;

    const handleSubmit = async (e) => {

        e.preventDefault();

        const config = {
            headers: {
              'Content-type': 'application/json',
            },
          };

          const imageData = new FormData();
          const imageDataTwo = new FormData();

          imageData.append('file', selectedImage);
          imageDataTwo.append('file', selectedImageTwo);
          imageData.append('upload_preset', 'image_upload');
          imageDataTwo.append('upload_preset', 'image_upload');
         
          let imageUpload = await Axios.post(url, imageData, cloudConfig);
          let imageUploadTwo = await Axios.post(url, imageDataTwo, cloudConfig);

        Axios.post("/testimonial", {title: title, testimonial: content, name: author, data: formData,
            imageUrl: imageUpload.data.url, imageUrlTwo: imageUploadTwo.data.url
            }, config);

           

          let path = 'Testimonials'; 
          history.push(path);
          window.location.reload();
    };
    
    return (
        <>
            <Form onSubmit={handleSubmit} encType='multipart/form-data' className='inptAreaTestimonial'>
                <Row className='my-4'>
                    <h1> New Testimonial </h1>
                </Row>
                <div className='addTestimonial'>
                    <label for="title">Title:</label>
                    <Row className='my-3'>
                        <input 
                            type="text" 
                            placeholder="Title" 
                            name="title"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            value={title}>
                        </input>
                    </Row>

                    <label for="contents">Contents:</label>
                    <Row className='my-3'>
                        <textarea  
                            type="text" 
                            placeholder="Contents"
                            name="contents"
                            onChange={(e) => {
                                setContent(e.target.value);
                            }}
                            value={content}>
                        </textarea>

                    <Row className='my-3'>
                        <input
                        name='file'
                        type='file'
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                        ></input>
                    </Row>    

                    <Row className='my-3'>
                        <input
                        name='file'
                        type='file'
                        onChange={(e) => setSelectedImageTwo(e.target.files[0])}
                        ></input>
                    </Row>  

                    </Row>
                    <label for="author">Author:</label>
                    <Row className='my-3'>
                        <input 
                            type="text" 
                            id='author' 
                            placeholder="Author" 
                            name="author"
                            onChange={(e) => {
                                setAuthor(e.target.value);
                            }}
                            value={author}>
                        </input>
                    </Row>
                    <Button className='btn-block' type='submit' >
                        CREATE NEW TESTIMONIAL
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default AddTestimonial;