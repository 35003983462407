import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';

const Confirmation = ({ match, history }) => {
  const [error, setError] = useState('');

  /**
   * Sets header configuration for axios.
   */
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  /**
   * On click confirms a user account.
   */
  const handleClick = async (e) => {
    e.preventDefault();

    /**
     * Updates user confirmed field to true.
     */
    try {
      await axios.put(
        `/Confirmation/${match.params.resetToken}`,
        {
          confirmed: true,
        },
        config
      );
      history.push('/SignIn');
    } catch (error) {
      setError(error);

      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  return (
    <div className='form-group'>
      <Form>
        <h1> Verify Account</h1>
        <Button type='submit' onClick={handleClick}>
          Verify Account
        </Button>
        <span className='errorMessage'>{error}</span>
      </Form>
    </div>
  );
};

export default Confirmation;
