import React, { useState, useEffect } from 'react';
import RescueListings from '../components/RescuesListings';
import axios from 'axios';
/**
 * Gets the list of Rescues to bring into the Donation Page.
 * @returns The RescuesListings component.
 */
const DonationPage = () => {
  const [rescueGroupData, setRescueData] = useState([]);

  useEffect(async () => {
    let donationData = await axios.get('/rescues');

    setRescueData(donationData.data);
  }, []);

  return rescueGroupData.map((items) => {
    return <RescueListings data={items} />;
  });
};
<h1>Rescues</h1>;

export default DonationPage;
